<template>
  <layout-default>
    <div :class="isAuthenticated ? 'added-padding' : 'added-padding-noauth'">
      <router-view />
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@core/layouts/layout-default/LayoutDefault.vue';
import { $themeConfig } from '@themeConfig';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  components: {
    LayoutDefault,
  },
  computed: {
    ...mapState('auth', {
      isAuthenticated: 'isAuthenticated',
    }),
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
};
</script>
